import React, { useState, useContext, useEffect } from "react"
import { useCookies } from 'react-cookie';
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import slug from "slug"

import axios from "axios"
import { parseISO } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"

/* Import Global Hook(s) */
import {
  useHomePage,
  useProgramPage,
  useProgramEventsSortedByDay,
} from "~queries/hooks"

/* Import Global Context(s) */
import TokenContext from "~context/token"
import AccountContext from "~context/account"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Slideshow from "~components/slideshow/slideshow"

import ProgramByYear from "~components/pages/program/byYear/byYear"

import PartnersRow from '~components/partners/partnersRow'

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Page Components */
import ProgramDay from "~components/pages/program/day/day"

/* Import Local Style(s) */
import "./program.scss"


// Uses 1.29 of date-fns formatting
const formatWithTimezone = (date, displayFormat) => {
  if(date == null){
    return '—'
  }
  const storedDate = new Date(date)
  const timeZone = "America/New_York"
  const displayedTime = formatToTimeZone(storedDate, displayFormat, {
    timeZone,
  })
  return `${displayedTime}`
}

const Program = ({ location }) => {
  const {
    pageTitle,
    headerTitle,
    _rawHeaderCopy,
    // programDays,
    programPanels,
    slideshow,
  } = useProgramPage()

  const [cookies, setCookie] = useCookies(['sessionToken']);

  // const [tokenString, setToken] = useContext(TokenContext);
  const [accountInfo, setAccountInfo] = useContext(AccountContext);


  const [eventsByDate, setEventsByDate] = useState([])

  const { displayProgramEvents } = useHomePage()
  const programEventsSortedByDay = useProgramEventsSortedByDay()
  const [panel, setPanel] = useState(slug(programPanels[0]?.title || ""))



  /*
    see if logged in...
    if so:
      show programs with rsvp link

  */

  useEffect(() => {

    const method = (cookies.sessionToken) ? 'with_auth' : 'get'
    const contents = (cookies.sessionToken) ? { token: cookies.sessionToken } : {}

    axios
    .post("/.netlify/functions/vipUsers", {
      contents: contents,
      method: method,
      path: "/events"
    })
    .then(res => {

      let dates = []

      if(!Array.isArray(res?.data?.data?.data)){
        console.log('invalid', res)
        return
      }

      // console.log(res.data.data.data)

      res.data.data.data.forEach(r => {
        const i = dates.map(d => d.date).indexOf(formatWithTimezone(r.dateFrom, "dddd D MMM"))
        
        if(i > -1){
          dates[i].events.push(r)
        }else{
          dates.push({
            date: formatWithTimezone(r.dateFrom, "dddd D MMM"), events: [r]
          })
        }
      })

      // console.log(dates)
      setEventsByDate(dates)

      if(cookies.sessionToken && !accountInfo){
        console.log('getting account info')
        axios
        .post('/.netlify/functions/vipUsers', {
          contents: {token: cookies.sessionToken},
          method: 'with_auth',
          path: '/vips/auth/me'
        })
        .then(result => {
          // console.log(result)
          if(result.data.data !== undefined && result.data.data !== null){
            setAccountInfo(result.data.data)
          }else{
            console.log('unauthorized')
          }

        })
      }



    })
    .catch(e => {
      console.error(e)
    })

  }, [axios, cookies, setEventsByDate])


  return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={headerTitle} className="program">
        {slideshow.images.length > 0 ? (
          <Slideshow slideshow={slideshow} />
        ) : null}
        <PageArticleSection>
          <div className="program-description font-size--small">
            <BlockContent blocks={_rawHeaderCopy} />  
          </div>
        </PageArticleSection>
        {/*displayProgramEvents &&
          programEventsSortedByDay.map(({ date, events }) => {
            return (events.length !== events.filter(e => e.vip).length || accountInfo !== false)
            ? (<PageArticleSection className="day program-description font-size--small" key={date}>
                <ProgramDay date={date} events={events} />
              </PageArticleSection>)
            : null
            })*/}

        {displayProgramEvents &&
          eventsByDate.map(({ date, events }) => {
            return (events.length !== events.filter(e => e.vip).length || accountInfo !== false)
            ? (<PageArticleSection className="day program-description font-size--small" key={slug(date)}>
                <ProgramDay date={date} events={events} tokenString={cookies.sessionToken} />
              </PageArticleSection>)
            : null
            })}
        {<PageArticleSection>
            <div className="panel-selectors">
              {programPanels.map(({ title, _key }) => (
                <button
                  className={classnames({ "is-active": panel === slug(title) })}
                  onClick={() => {
                    setPanel(slug(title))
                  }}
                  key={_key}
                >
                  {title}
                </button>
              ))}
            </div>
            <AnimatePresence initial={false} exitBeforeEnter>
              {programPanels.map(({ title, _rawCopy, _key, partnersListed }) => {
                return panel === slug(title) ? (
                  <motion.div
                    className={classnames("panel", `${slug(title)}-panel`)}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={elementTransitionAnimation.variants}
                    key={_key}
                  >
                    <BlockContent blocks={_rawCopy} />
                    {partnersListed.map((l, i) => {
                      if(l._rawBodyCopy){
                        return (<BlockContent key={_key + i} blocks={l._rawBodyCopy} />)
                      }else{
                        return (<PartnersRow key={_key + i} classes="scaled" partners={l.partners} />)
                      }
                    })}
                  </motion.div>
                ) : null
              })}
            </AnimatePresence>
          </PageArticleSection>}


          <ProgramByYear />

      </PageArticle>
      <Footer />
    </Page>
  )
}

export default Program
