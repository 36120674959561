import React, { useState } from "react"
import classnames from "classnames"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import Image from "~components/image/image"

/* Import Local Style(s) */
import "./partnersRow.scss"


const PartnersRow = ({ partners, classes }) => {

	return (<div className={classnames('partners_row', 'partners_row--' + partners.length, classes)}>
		{partners.filter(p => p != null && p != undefined).map((partner, j) => {
			const w = (partner.width !== undefined && partner.width !== null && partner.width != 100) ? `calc(${partner.width}% - .5rem)` : '100%'
				
			if(partner.externalLink !== null && partner.externalLink !== undefined){
				return (<a className="partner_single" href={partner.externalLink} target="_blank" style={{width : w, flexBasis: w}} key={'p_' + j}>
					{partner.logo?.asset?.gatsbyImageData && <Image image={partner.logo.asset.gatsbyImageData} />}
				</a>)
			}else{
				return (<div className="partner_single" style={{width : w, flexBasis: w}} key={'p_' + j}>
					{partner.logo?.asset?.gatsbyImageData && <Image image={partner.logo.asset.gatsbyImageData} />}
				</div>)
			}

	})}
	</div>)
}

export default PartnersRow