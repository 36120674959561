import React, { useEffect, useState, useContext } from "react"
import classnames from "classnames"
import { motion, AnimatePresence } from "framer-motion"
import slug from "slug"


/* Import Global Hook(s) */
import { useProgramPage } from "~queries/hooks"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Component(s) */
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import BlockContent from "~components/blockContent/blockContent"

/* Import Local Style(s) */
import "./byYear.scss"


const ProgramByYear = ({}) => {

  const { programSections, programSectionsHouston } = useProgramPage()
  const [panel, setPanel] = useState("")
  const [year, setYear] = useState()
  const hasYear = (year !== undefined && year !== null && year !== '') ? true : false

  const [isMiami, setIsMiami] = useState(true)

  return (
    <div className="programs-by-year">
      
      <AnimatePresence exitBeforeEnter>
        {(
          <motion.ul
            className="years font-size--small"
            key="years"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={elementTransitionAnimation.variants}
          >
            {programSections.length > 0 && <li className="font-size--small">Miami Beach Previous Years: </li>}

            {programSections.map((s, i) => {
              return (
                <li key={s.year + i} className="font-size--small">
                  <button
                    className={classnames({ active: s.year === year })}
                    onClick={() => {
                      (s.year === year) ? setYear() : setYear(s.year); setIsMiami(true);
                    }}
                  >
                    {s.year}
                  </button>
                </li>
              )
            })}
          </motion.ul>
        )}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {(
          <motion.ul
            className="years font-size--small"
            key="years"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={elementTransitionAnimation.variants}
          >
            {programSectionsHouston.length > 0 && <li className="font-size--small">Houston Previous Years: </li>}

            {programSectionsHouston.map((s, i) => {
              return (
                <li key={s.year + i} className="font-size--small">
                  <button
                    className={classnames({ active: s.year === year })}
                    onClick={() => {
                      (s.year === year) ? setYear() : setYear(s.year); setIsMiami(false);
                    }}
                  >
                    {s.year}
                  </button>
                </li>
              )
            })}
          </motion.ul>
        )}
      </AnimatePresence>

      {hasYear && isMiami &&  programSections.map((s, i) => {
        return s.year === year ? <PageArticleSection key={s._key}>
        <div className="panel-selectors">
          {s.programPanels.map(({ title, _key }) => (
            <button
              className={classnames({ "is-active": panel === slug(title) })}
              onClick={() => {
                setPanel(slug(title))
              }}
              key={_key}
            >
              {title}
            </button>
          ))}
        </div>
        <AnimatePresence initial={false} exitBeforeEnter>
          {s.programPanels.map(({ title, _rawCopy, _key, partnersListed }) => {
            return panel === slug(title) ? (
              <motion.div
                className={classnames("panel", `${slug(title)}-panel`)}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
                key={_key}
              >
                <BlockContent blocks={_rawCopy} />
                {partnersListed.map(l => {
                  if(l._rawBodyCopy){
                    return (<BlockContent blocks={l._rawBodyCopy} />)
                  }else{
                    return (<PartnersRow classes="scaled" partners={l.partners} />)
                  }
                })}
              </motion.div>
            ) : null
          })}
        </AnimatePresence>
      </PageArticleSection> : null })}

      {hasYear && !isMiami &&  programSectionsHouston.map((s, i) => {
        return s.year === year ? <PageArticleSection key={s._key}>
        <div className="panel-selectors">
          {s.programPanels.map(({ title, _key }) => (
            <button
              className={classnames({ "is-active": panel === slug(title) })}
              onClick={() => {
                setPanel(slug(title))
              }}
              key={_key}
            >
              {title}
            </button>
          ))}
        </div>
        <AnimatePresence initial={false} exitBeforeEnter>
          {s.programPanels.map(({ title, _rawCopy, _key, partnersListed }) => {
            return panel === slug(title) ? (
              <motion.div
                className={classnames("panel", `${slug(title)}-panel`)}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
                key={_key}
              >
                <BlockContent blocks={_rawCopy} />
                {partnersListed.map(l => {
                  if(l._rawBodyCopy){
                    return (<BlockContent blocks={l._rawBodyCopy} />)
                  }else{
                    return (<PartnersRow classes="scaled" partners={l.partners} />)
                  }
                })}
              </motion.div>
            ) : null
          })}
        </AnimatePresence>
      </PageArticleSection> : null })}

    </div>
  )
}

export default ProgramByYear
